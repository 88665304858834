import { richText, asText } from '@/utils/html';

export default ({ primary }) => {
  let title, videoTitle;

  if (typeof primary.video_title === 'string') {
    title = primary.video_title;
    videoTitle = primary.video_title;
  } else if (primary.video_title && primary.video_title.length) {
    title = richText(primary.video_title);
    videoTitle = asText(primary.video_title);
  }

  return {
    title,
    videoTitle,
    videoId: primary.video_id,
    largePlayer: primary.large_player,
  };
};
